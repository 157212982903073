//ヘッダー ハンバーガーメニュー、ユーザメニュー開閉
const menu = () => {
  // ヘッダーユーザー メニュー 表示非表示
  var $userMenu = $('.header__menu__list-hide'),
    $container = $('.l-container');

  var closeMenu = function() {
    $userMenu.removeClass('is-open');
  }

  $container.on('click', function(e) {
    if($userMenu.hasClass('is-open')){
      closeMenu();
    }
  });

  $('.global-header__user').on('click', function(e){
    e.stopPropagation();
    $userMenu.toggleClass('is-open');
  });

  $userMenu.on('click', function(e) {
    e.stopPropagation();
  });

  // ハンバーガーメニュー 表示非表示
  var $hamburger = $('.global-header__hamburger'),
    $hamburgerMenu = $('.global-header__menu-container');

  var closeHumburgerMenu = function() {
    $hamburger.removeClass('is-open');
    $hamburgerMenu.removeClass('is-open');
  }

  var timer = '';
  $(window).on('resize', function(){
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(function(){
      var windowSize = $(window).width();
    
      if (windowSize > 769) {
        $container.on('click', function(e) {
          if($hamburgerMenu.hasClass('is-open')){
            closeHumburgerMenu();
          }
        });
      } else {
        ;
      }
    }, 200);
  });

  $hamburger.on('click', function(e){
    e.stopPropagation();
    $hamburger.toggleClass('is-open');
    $hamburgerMenu.toggleClass('is-open');
  });

  $hamburgerMenu.on('click', function(e){
    e.stopPropagation();
  });
}

// ヘッダー ナビゲーション カレント表示
$(window).on('load', function (){
  var pageURL = location.pathname,
  pageURLArr = pageURL.split('/'), //パスを分割して配列化する
  pageURLArrCategory = pageURLArr[1]; //パスから第1階層を取得

  $('.header__menu > ul > li > a').each(function (i, v) {
    var selfhref = $(v).attr('href'),
      hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
      hrefArrCategory = hrefArr[1]; //href属性の第1階層を取得
    //パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
    if (pageURLArrCategory === hrefArrCategory) {
      $(v).parent().addClass('is-current');
    }
  });
});

// スムーススクロール
$(function () {
  $('a[href^="#"]').on('click', function(){
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    var speed = 500;
    $("html, body").animate({
      scrollTop: position
    }, speed, "swing");
    return false;
  });
});

// よくあるご質問 回答の開閉
$(function(){
  //クリックで動く
  $('.question').on('click', function(){
    $(this).toggleClass('is-open');
    $(this).next('.answer').slideToggle();
  });
});

// 一覧系SP並び替えボタン
$(function(){
  //クリックで動く
  $('.display__sort-menu').on('click', function(){
    $(this).toggleClass('is-open');
    $(this).next('.display__sort').toggleClass('is-open');
  });
});

// 商品詳細 pdfリスト 位置変更
const pdfList = () => {
  $(function(){
    var windowSize = $(window).width();
    if (windowSize < 769) {
      $(".product-detail__pdf").appendTo('.product-detail');
    } else {
      ;
    }
  });
}